<template>
  <div>
    <div v-if="isSingle" :class="className">
      {{ value }}
    </div>
    <div v-else :class="className">
      {{ values }}
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'values', 'className'],
  computed: {
    isSingle () {
      return "undefined" !== typeof this.value;
    }
  }
}
</script>
<style scoped>

</style>